<template>
    <div class="cadre-content">
        <ul class="navbar">
            <li>
                <router-link
                    :to="{name: 'BilanBanque'}"
                >
                    Mon Bilan Par Mois
                </router-link>
            </li>
            <li>
                <router-link
                    :to="{name: 'InterlocuteurBanque'}"
                >
                    Mes Principales Banques
                </router-link> 
            </li>
        </ul>    
        
        <router-view></router-view>
    </div>
</template>

<script>
  export default {
    name: 'TableauDeBord',
    data() {
      return {

      }
    },
    created() {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
    @import "~courtisia-style/scss/colors";

    .navbar a {
        left: 0 !important;
    }

</style>
