<template>
    <div>
        <router-link
            class="lien-retour"
            :to="{name: 'TableauDeBord', query: {dateDebut : this.dateDebut, dateFin : this.dateFin}}"
        >
            <i class="fas fa-chevron-left"></i> Retour au tableau de bord
        </router-link>
        <div class="cadre">
            <div class="cadre-tete cadre-tete--menu">
                DOSSIERS EN ALERTE

                <filters
                    api="dossiersEnAlerteCharger"
                    :filtrer="filtrer"
                    :actions="[]"
                    :pagination="true"
                    :defaultData="{dateDebut, dateFin}"
                ></filters>
            </div>
            <div class="cadre-content">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Intervenant</th>
                        <th>Dossier</th>
                        <th>Etat d'avancement</th>
                        <th>Dernier avancement</th>
                        <th class="number">CA Brut</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        class="row"
                        v-for="(row, idx) in rows"
                        :key="idx"
                    >
                        <td>{{row.intervenant}}</td>
                        <td>
                            <div>{{row.dossier}}</div>
                            <small>
                                {{row.dossier_numero}}
                            </small>
                        </td>
                        <td>
                            <span class="badge badge--etat"
                                  :style="etatAvancementColor(row.avancement_ordre)"
                            >
                                <span v-html="row.etat_avancement"></span>
                            </span>
                        </td>
                        <td>
                            {{row.date_dossier_action|date}}
                            <span
                                class="badge badge--jour"
                                :style="dernierAvancementJoursColor(row.date_dossier_action)">
                                 {{dernierAvancementJoursCalculer(row.date_dossier_action)}}
                            </span>
                        </td>
                        <td class="number">
                            {{row.total_potentiel}} {{$compte.societe_devise}}
                        </td>
                        <td class="center">
                            <i
                                class="fas fa-folder fa-2x"
                                @click="dossierCliquer(row.id)"
                            ></i>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="pagination-container">
                    <paginate
                        :page-count="Math.ceil(total / limite)"
                        :click-handler="clickPage"
                        :prev-text="'&#10094;'"
                        :next-text="'&#10095;'"
                        :container-class="'pagination'">
                    </paginate>
                </div>
            </div>
        </div>

        <div class="chargement" v-if="chargement">
            <div>
                <b> Chargement en cours... </b>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '@/api';
  import dayjs from 'dayjs'

  export default {
    name: 'DossiersEnAlerte',
    data() {
      return {
        rows: [],
        total: 0,
        limite: 10,
        dateDebut: dayjs().startOf('year').format('YYYY-MM-DD'),
        dateFin: dayjs().endOf('year').format('YYYY-MM-DD'),
        page: 1
      }
    },
    created() {
        const {dateDebut, dateFin} =  this.$route.query || {}
        this.dateDebut = dateDebut ? dateDebut : this.dateDebut;
        this.dateFin = dateFin ? dateFin : this.dateFin;
      this.charger()
    },
    methods: {
      dossierCliquer(dossierId) {
        const e = new CustomEvent('dossierCharger', {detail: {dossierId}})
        window.dispatchEvent(e)
      },
      dernierAvancementJoursCalculer(date) {
        const nbJours = dayjs().diff(dayjs(date), 'day')
        return nbJours > 0 ? `${nbJours} jours` : `${nbJours} jour`
      },
      dernierAvancementJoursColor(date) {
        const nbJours = dayjs().diff(dayjs(date), 'day')
        if (nbJours < 10) {
          return {'background-color': 'green'}
        }
        if (nbJours > 10 && nbJours < 30) {
          return {'background-color': 'orange'}
        }
        if (nbJours > 30) {
          return {'background-color': 'red'}
        }
      },
      etatAvancementColor(ordre) {
        return {backgroundColor: `rgba(4, 95, 242, ${(ordre / 100) + ordre})`}
      },
      clickPage(page) {
        this.page = page
        this.charger()
      },
      charger() {
        this.chargement = true

        api.dossiersEnAlerteCharger({
            page: this.page,
            limite: this.limite,
            debut: this.dateDebut,
            fin: this.dateFin,
          }
        ).then((d) => {
          const {total, data} = d.data
          this.rows = data
          this.total = total

          this.chargement = false
        })
      },
      filtrer(data) {
        this.rows = data.data
        this.total = data.total
        this.dateDebut = data.debut
        this.dateFin = data.fin
        this.intervenant = data.intervenant
        this.page = 1
      }
    }
  }
</script>

<style lang="scss" scoped>

    @import "~courtisia-style/scss/colors";

    .cadre {
        height: auto !important;
    }

    .cadre-content {
        position: relative;
    }

    .badge {
        margin: 0;
        text-align: center;
        &.badge--etat {
            width: 100px;
            display: inline-block;
        }

        &.badge--jour {
            min-width: 50px;
            display: inline-block;
        }
    }

    .lien-retour {
        text-decoration: none;
        color: $gray;
        display: block;
        padding: 10px;
    }

    .pagination-container {
        display: flex;
        justify-content: center;
        padding: 10px;
    }

    .table {
        font-size: .75rem;
    }
</style>
