<template>
    <div>
        <div class="filters_ctn">
            <div class="filter">
                <label>Année</label>
                <select class="online select_date vertical_align form-control" v-model="dateSelectionnee">
                    <option value="0">{{dateMaintenant}}</option>
                    <option value="1">{{dateMaintenant - 1}}</option>
                    <option value="2">{{dateMaintenant - 2}}</option>
                    <option value="3">{{dateMaintenant - 3}}</option>
                    <option value="4">{{dateMaintenant - 4}}</option>
                    <option value="5">{{dateMaintenant - 5}}</option>
                    <option value="6">{{dateMaintenant - 6}}</option>
                </select>
            </div>
            <div class="filter" v-if="mandataires.length > 1">
                <label>Mandataires</label>
                <button type="button" class="btn btn-default"
                        v-on:click="openModalMandataire = true"
                >
                    {{ mandataireSelectionnes.length === 0 ? 
                        "Tous les mandataires" : mandataireSelectionnes.length + " mandataire(s) séléctionné(s)"}}
                </button>

            </div>
            <div class="filter">
                <label>Banques</label>
                <button type="button" class="btn btn-default"
                        v-on:click="openModalBanque = true"
                >
                    {{ interlocuteurSelectionnes.length === 0 ? "Toutes les banques" : interlocuteurSelectionnes.length + " banque(s) séléctionnée(s)"}}
                </button>
            </div>
            <div class="actions">
                <a class="btn btn-primary" @click="charger()"> Filtrer </a>

                <a 
                    class="btn btn-default btn-exporter" :disabled="data == null" @click="exporter()"
                >
                    <i class="fas fa-download"></i> Exporter 
                </a>
            </div>
        </div>

        <modal
                v-if="openModalMandataire"
                :close="() => openModalMandataire = false"
        >
            <div class="modal-content">
                <div class="cadre">
                    <div class="cadre-tete cadre-tete--menu">
                        Filtrer par mandataire
                    </div>
                    <div class="cadre-content" >
                        <div class="items">
                            <a
                                    class="item"
                                    v-on:click="mandataireSelectionnes = []"
                                    v-bind:class="{'item-select': mandataireSelectionnes.length === 0}"
                            >
                                Tous les mandataires
                            </a>

                            <a v-for="(mandataire, index) in mandataires"
                               v-bind:key="index"
                               class="item"
                               v-bind:class="{'item-select': mandataireSelectionnes.includes(mandataire[0])}"
                               v-on:click="ajouterMandataire(mandataire[0])"
                            >
                                {{ mandataire[1] }}
                            </a>
                        </div>
                    </div>
                    <div class="cadre-pied background-primary">
                        <button
                                type="submit"
                                class="btn btn-transparent"
                                @click="openModalMandataire = false"
                        >
                            Sélectionner
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <modal
            v-if="openModalBanque"
            :close="() => openModalBanque = false"
        >
            <div class="modal-content">
                <div class="cadre">
                    <div class="cadre-tete cadre-tete--menu">
                        Filtrer par banque
                    </div>
                    <div
                        class="cadre-content"
                    >

                        <div class="items">
                            <a  
                                class="item"
                                v-on:click="interlocuteurSelectionnes = []"
                                v-bind:class="{'item-select': interlocuteurSelectionnes.length === 0}"
                            >
                                Toutes les banques
                            </a>

                            <a v-for="(interlocuteur, index) in interlocuteurs"
                                 v-bind:key="index"
                                 class="item"
                                 v-bind:class="{'item-select': interlocuteurSelectionnes.includes(interlocuteur[0])}"
                                 v-on:click="ajouterBanque(interlocuteur[0])"
                            >
                                {{ interlocuteur[1] }}
                            </a>    
                        </div>

                        
                    </div>
                    <div class="cadre-pied background-primary">
                        <button
                            type="submit"
                            class="btn btn-transparent"
                            @click="openModalBanque = false"
                        >
                            Sélectionner
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <div class="conteneur-content">

            <div class="conteneur-instructions" v-if="data == null">
                <i class="fas fa-chart-area"></i>

                <h3> Aucune donnée à afficher </h3>
                <p>
                    Personnalisez vos filtres <br> pour afficher vos résultats
                </p>

                <div class="cta">
                    <a class="btn btn-primary" @click="charger()"> Filtrer</a>
                </div>
            </div>
        
            <Chart
                    v-if="valeursGraphique != null"
                    v-bind:data="valeursGraphique"
                    v-bind:labels="labels"
            ></Chart>

            <div class="conteneur-table">
                <table class="table" v-if="data != null">
                    <tr>
                        <td></td>
                        <td class="gras">Nombre de Dossiers envoyés</td>
                        <td class="gras">Nombre de dossiers acceptés</td>
                        <td class="gras">Nombre de Dossiers finalisés (en Signature acte)</td>
                        <td class="gras">Montant des Honoraires banques facturés</td>
                        <td class="gras">Montant financé</td>
                    </tr>
                    <tr v-for="(mois, index) in data" v-bind:key="index">
                        <td class="gras">{{mois[0]}}</td>
                        <td>{{mois[1]}}</td>
                        <td>{{mois[2]}}</td>
                        <td>{{mois[3]}}</td>
                        <td>{{Math.round(mois[4] * 100) / 100}} €</td>
                        <td>{{Math.round(mois[5] * 100) / 100}} €</td>
                    </tr>
                </table>
            </div>    
        </div>

        
    </div>
</template>

<script>

  import api from '@/api'
  import dayjs from 'dayjs'
  import Chart from "../../../components/Chart";
  import saveAs from 'file-saver'

  export default {
    name: 'TableauDeBord',
    components: {
        Chart
    },
    data() {
      return {
          id_user: 8,
          data: null,
          optionSelect: [],
          dateMaintenant: 0,
          dateSelectionnee: 0,
          interlocuteurSelectionnes: [],
          interlocuteurs: [],
          request: [],
          mandataires: [],
          mandataireSelectionnes: [],
          valeursGraphique: null,
          labels: null,
          openModalMandataire: false,
          openModalBanque: false
      }
    },
    created() {
        this.dateMaintenant = parseInt(dayjs().format('YYYY'));
        this.get_liste_banque();
        this.get_liste_mandataire();
    },
    methods: {
        charger(){
            this.$root.$emit('chargementAfficher', true);

            this.request[0] = (this.interlocuteurSelectionnes && this.interlocuteurSelectionnes.length) ? this.interlocuteurSelectionnes  : 0;
            this.request[1] = (this.mandataireSelectionnes && this.mandataireSelectionnes.length) ? this.mandataireSelectionnes : 0;
            this.request[2] = parseInt(this.dateMaintenant) - parseInt(this.dateSelectionnee);
            this.valeursGraphique = null;
            api.banqueBilan(this.request).then((d) => {
                this.data = d.data;
                this.valeursGraphique = [[], [], []];
                this.labels = [];
                d.data.forEach(element => this.valeursGraphique[0].push(element[1]),
                );
                d.data.forEach(element => this.valeursGraphique[1].push(element[2]),
                );
                d.data.forEach(element => this.valeursGraphique[2].push(element[3]),
                );
                d.data.forEach(element => this.labels.push(element[0]),
                );
            });
            this.request = [];

            this.$root.$emit('chargementAfficher', false);
        },
        get_liste_banque(){
            api.get_liste_banque(this.id_user).then((d) => {
                this.interlocuteurs = d.data;
            });
        },
        get_liste_mandataire(){
            api.get_liste_mandataire(this.id_user).then((d) => {
                this.mandataires = d.data;
            });
        },
        exporter() {
            if (this.data == null)
                return;

            this.$root.$emit('chargementAfficher', true);
            const blob = new Blob([this.formatQS(this.data)], {type: "text/csv;charset=utf-8"});
            saveAs(blob, "export.csv");
            this.$root.$emit('chargementAfficher', false);

        },
        formatQS(data) {

            var csv = 'Mois,Nombre de dossiers envoyés,Nombre de Dossiers Nombre de dossiers acceptés, Nombre de Dossiers finalisés (en Signature acte), Montant des Honoraires banques facturés\n';
            data.forEach(function(row) {
                csv += row.join(',');
                csv += "\n";
            });

            return csv;
        },
        ajouterMandataire(id) {
            if (this.mandataireSelectionnes.includes(id)){
                this.mandataireSelectionnes.splice(this.mandataireSelectionnes.indexOf(id),1);
            } else {
                this.mandataireSelectionnes.push(id);
            }
        },
        ajouterBanque(id) {
            if (this.interlocuteurSelectionnes.includes(id)){
                this.interlocuteurSelectionnes.splice(this.interlocuteurSelectionnes.indexOf(id),1);
            } else {
                this.interlocuteurSelectionnes.push(id);
            }
        }
    }
  }
</script>

<style lang="scss" scoped>
    @import "~courtisia-style/scss/colors";

</style>
